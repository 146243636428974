<template>
    <div>
        <Loading v-if="$global.state.loading && $global.state.chats" />

        <v-row align="center" class="mb-3">
            <v-col cols="12" md="2">
                <v-btn @click="openAddDialog()" color="primary" large class="secondary--text btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة {{this.$route.meta.single}}</h3>
                </v-btn>
            </v-col>
        </v-row>
        <v-container>
            <v-fade-transition mode="out-in">
                <v-row>
                    <v-col cols="12" md="3" sm="12" v-for="(item,i) in $global.state.sliders" :key="i">
                        <v-card flat class="shadow animation-card">
                            <v-img :src="item.imageUrl" height="180" class="grey darken-4"></v-img>
                            <v-card-subtitle ><div class="mb-2">{{new Date(item.created).toISOString().substring(0,10)}}</div>
                            <v-btn small class="red darken-3" dark @click="deleteItem(item.id, index)">
                                حذف
                            </v-btn>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-fade-transition>
        </v-container>
        
        
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog")
    },
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,

            headers: [
                {
                    text: "#",
                    value: "id"
                },
                {
                    text: "صورة",
                    value: "imageUrl"
                },
                {
                    text: "تاريخ الإضافة",
                    value: "created",
                    align: "center"
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center"
                }
            ]
        };
    },

    mounted() {
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then(res => {
                            this.$eventBus.$emit(`refresh`);
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success"
                            });
                            this.$global.state.sliders.splice(i, 1);
                        })
                        .catch(err => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error"
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add"
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        }
    }
};
</script>
